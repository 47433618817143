<template>
<div>
    <div class="breadcrumb_header">
        <div class="breadcrumb">
        <h4 style="margin:0;padding-left: 10px;">NeXa</h4>
        <span class="divider"></span>
        <span>Draft a Contract</span>
        </div>
    <div class="open_sidebar" @click="toggleSidebar">
      <span class="material-symbols-rounded icon">keyboard_double_arrow_left</span>
      <p>Open Sidebar</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-1 mt-3">
        <div class="d-flex justify-content-start cursor-pointer back" @click="goBack">
      <span class="material-symbols-rounded" style="margin: 0;">arrow_back</span>
      <span style="margin: 0 8px;">Back</span>
    </div>
    </div>
    <div class="col-md-10 mt-5"> 
        <section class="uploads">
            <h4>Draft a Contract</h4>
            <form class="search-bar" role="search">
          <div class="input-group">
            <label for="legalQuery" class="visually-hidden">Enter a detailed prompt to easily generate a custom agreement tailored to your unique needs and specifications. e.g., Create a Non-Disclosure Agreement between...</label>
            <input
              type="text"
              id="legalQuery"
              class="input-field" placeholder="Enter a detailed prompt to easily generate a custom agreement tailored to your unique needs and specifications. e.g., Create a Non-Disclosure Agreement between..."
              aria-label="Enter a detailed prompt to easily generate a custom agreement tailored to your unique needs and specifications. e.g., Create a Non-Disclosure Agreement between..."
            />
          </div>
          <footer class="search-bar-footer">
            <div>
              <span class="char-count">0 / 3000</span>
            </div>
          </footer>
          <div class="d-flex justify-content-end mt-3">
            <div class="upload me-2" @click="uploadFile">
            <span class="material-symbols-rounded icon">upload</span>
            Create a MS Word</div>
            <div class="upload" @click="uploadFile">
            <span class="material-symbols-rounded icon">send</span>
            Generate Contract</div>
            </div>
            <div v-if="isCountry" class="country-option">
              <ul>
                <li
                  v-for="(flag, countryCode) in filteredCountryImages"
                  :key="countryCode"
                  @click="selectCountry(countryCode)"
                  class="countryOption"
                >
                  <img :src="flag" alt="Country Flag" class="country-flag" />
                  <span>{{ countryCode }}</span>
                </li>
              </ul>
            </div>
            <b-modal
            id="attachfile"
            hide-footer
            hide-header
            size="lg"
          >
          <div class="d-flex justify-content-between">
            <div>
              <h5>Upload Files</h5>
            </div>
            <div>
              <span class="material-symbols-rounded close-icon" @click="hideAttachModal">close</span>
            </div>
          </div>
          <div>
              <FileInput
            style="flex-grow: 1;"
            :accept="['pdf']"
            @input-files="$set(files, 0, $event)"
          />
          <p class="mt-2" style="font-size:11px;font-weight: 400;">Your files will be securely stored in NeXa, accessible only to you and your workspace admins, and you can delete them at any time.</p>
          <FileProcess
            style="width: 100%; max-height: 200px; overflow-y: auto;"
            :accept="['pdf']"
            :files="files[0]"
            @output-files="$set(files, 1, $event)"
          />
          <div class="d-flex mt-2 swift" v-if="files.length > 0 && files[0].length > 0">
            <!-- <span class="material-symbols-rounded icon">toggle_on</span> -->
            <div class="toggle">
            <div class="switch"><div class="button"></div></div>
          </div>
            Organize files into folders
          </div>
          </div>
          <footer class="mt-3">
            <div class="d-flex justify-content-end">
              <div class="upload" @click="uploadFile">
              <span class="material-symbols-rounded icon">upload</span>
              Upload</div>
            </div>
          </footer>
          
            </b-modal>           
        </form>
        </section>
    </div>
    <div class="col-md-1">
    </div>
    </div>
</div>
</template>
<script>
import countryImages from './../../../src/assets/country/index';
import FileInput from './../../components/input/FileInput.vue';
import FileProcess from './../../components/input/FileProcess.vue';
export default {
    components: {
        FileInput, FileProcess
  },
    data(){
        return{
            loading:true,
            sidebarCollapsed: false,
            lawbotVisible:false,
            isCountry:false,
            countryImages,
            files: [[], []],
        }
    },
    computed: {
    filteredCountryImages() {
      return Object.entries(this.countryImages).filter(
        ([countryCode]) => countryCode !== 'USA' // Filter out USA
      ).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
    }
  },
    methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
        this.$router.push({ name: 'ExpertMode' });
        },
        toggleCountry(){
      this.isCountry = !this.isCountry;
    },
    showAttachModal() {
      this.$bvModal.show('attachfile');
    },
    hideAttachModal() {
      this.$bvModal.hide('attachfile');
    },
    uploadFile(){
        this.$router.push({ name: 'DeepResearchResult' });
    }
    }
}
</script>

<style scoped>
.page-content{
  padding: 0;
}
.breadcrumb_header{
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background: #fff;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
  span{
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}
.open_sidebar {
  height: 40px;
  background: var(--primary);
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center; /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
}

.open_sidebar span {
  color: #fff;
  display: flex; /* Ensure icon is aligned vertically */
  align-items: center; /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0; /* Remove any default margin to align the text properly */
  line-height: 1; /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center; /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
.back {
  color: var(--primary) !important;
  font-weight: 500;
  padding: 5px;
}
.col-md-10 {
  overflow-y: auto; /* Allows vertical scrolling if content exceeds the height */
  max-height: 100vh; /* Ensure it doesn’t exceed the viewport height */
}
.uploads {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  margin-top: 24px;
}

.search-bar-footer {
  background: var(--Neutral-Interface-Grey, #FAFBFC);
  border-top: 1px solid var(--Neutral-Light-Grey, #F2F3F3);
 background: var(--Neutral-Light-Grey, #F2F3F3);
  display: flex;
  justify-content: flex-end;
  padding:15px 15px 0 15px;
  margin-top: -16px;
}
.search-bar {
  border-radius: 8px;
  margin-top: 24px;
  width: 100%;
}

.input-group {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
 background: var(--Neutral-Interface-Grey, #FAFBFC);     
}.input-field {
  flex: 1;
  background: var(--Neutral-Interface-Grey, #FAFBFC);   
  color: var(--Neutral-Mid-Grey, #d1d2d5);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  padding: 12px;
  border: none;
  outline: none;}

.upload-button {
  border-radius: 4px;
  overflow: hidden;
  padding: 4px;
}

.upload-button img {
  width: 20px;object-fit: contain;
}

.attach-button,
.country-button {
  color: #86888D;
  font-size: 14px;
  font-weight: 500;
}.char-count {
  color: var(--Neutral-Mid-Grey, #d1d2d5);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.icon-small {
  color: #86888D;
  margin: auto;
  font-size: 18px;
}

.upload{
 background: var(--Neutral-Light-Grey, #F2F3F3);
  border-radius: 6px;
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Neutral-Mid-Grey, #D1D2D5);
}

.country-option{
margin-left: 20%;
margin-top: -3%;
  left: 18%;
  width: 100px !important;
  background: #FAFBFC;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
  position: absolute;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.country-flag {
  width: 18px;
  height: 18px;
  margin-right:10px;
}
.countryOption{
  cursor: pointer;
 margin-bottom: 10px;
 span{
  color:#86888D;
  font-size:14px;
 }
}
</style>