<template>
  <div
    id="container"
    @click="chooseFile"
    @dragover.prevent
    @drop.prevent="handleInput"
  >
    <input v-if="!single"
      id="FileUpload"
      ref="FileUpload"
      type="file"
      multiple
      maxlength="20"
      @change="handleInput"
    />
    <input v-else
      id="FileUpload"
      ref="FileUpload"
      type="file"
      @change="handleInput"
    />

    <span class="material-symbols-rounded upload">upload_file</span>
    <p class="desc">Drag & Drop or <span>Choose file</span> to upload</p>
    <p class="dark-grey subtitle">{{ `File format: ${displayFileExt}` }}</p>
  </div>
</template>

<script>
export default {
  props: {
    // Array of accepted file extentions
    accept: { type: Array },

    // Single file input
    single: { type: Boolean, default: false }
  },

  computed: {
    displayFileExt() {
      return this.accept
        .reduce((a, x) => {
          if (["png", "jpg", "jpeg"].includes(x.toLowerCase())) {
            if (!a.includes("IMAGE")) {
              a.push("IMAGE");
            }
          } else {
            a.push(x.toUpperCase());
          }
          return a;
        }, [])
        .join(" / ");
    },
  },

  methods: {
    chooseFile() {
      this.$refs.FileUpload.click();
    },

    handleInput(e) {
      const Files = e.dataTransfer?.files ?? e.target.files ?? null;
      this.$emit("input-files", Array.from(Files));
    },
  },
};
</script>

<style scoped>
input {
  display: none;
}

p {
  margin-bottom: 4px;
}

.dark-grey {
  color: var(--dark-grey) !important;
}

#container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--interface-grey);
  border: 2px dashed var(--mid-grey);
  border-radius: 8px;
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    p {
      margin: 0px 0px 0px 4px;
    }

    span {
      font-size: 18px;
    }
  }
}

.upload {
  margin: 27px 0px 8px 0px;
  font-size: 64px;
  font-variation-settings: "FILL" 1;
  color: var(--dark-grey);
}

.desc {
  font-size: 16px;
  font-weight: 500;

  span {
    color: var(--primary);
  }
}

.subtitle {
  text-align: center;
  margin-bottom: 27px;
}
</style>
